<template>
  <auth-layout :title="$t('Login')"
    :loading="loading">

    <template v-slot:body>
      <v-text-field
          outlined
          name="email"
          v-model="form.email"
          :error-messages="firstError('form.email')"
          label="Email"
          type="email"
      ></v-text-field>
      <v-text-field
        outlined
        id="password"
        name="password"
        v-model="form.password"
        :error-messages="firstError('form.password')"
        :label="$t('Password')"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
        @click:append="showPassword = !showPassword"
      ></v-text-field>

      <social-auth/>

      <router-link to="reset-password" class="text-decoration-none">
        {{ $t('Reset Password') }}?</router-link>
    </template>

    <template v-slot:footer>
      <router-link to="register">{{ $t('Register') }}</router-link>
      <v-spacer></v-spacer>
      <v-btn
          :loading="loading"
          type="submit"
          @click="submit"
          color="primary"
          class="px-4"
          dark
      >{{$t('Submit Login')}}</v-btn>
    </template>
  </auth-layout>
</template>
<style lang="scss">
.btn-social{
  color:rgba(0, 0, 0, 0.6) !important;
  border-color:#b0b0b0;
  display:flex;
  justify-content: left;
  align-items: center;
  text-transform: none;
  img{
    margin-right:100px;
  }
  &:hover{
    border-color:#222222;
    color:#484848 !important;
  }
}
</style>
<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, email } from 'vuelidate/lib/validators';
import SocialAuth from './components/SocialAuth.vue';
import FormValidationMixin from '../../lib/FormValidationMixin';

export default {
  components: {
    SocialAuth,
  },
  mixins: [validationMixin, FormValidationMixin],
  validations: {
    form: {
      email: {
        email,
        required,
        txtMinLen: minLength(2),
      },
      password: {
        required,
        txtMinLen: minLength(2),
      },
    },
  },
  data() {
    return {
      form: {
        email: undefined,
        password: undefined,
      },
      loading: false,
      showPassword: false,
    };
  },
  methods: {
    submit() {
      this.$v.$touch(); // validation
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch('tryLogin', {
          ...this.form,
        })
        .then(() => {
          // this.loading = false;
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.loading = false;
        });
    },
  },
  mounted() {
    if (
      window.navigator
      && window.navigator.userAgent
      && window.navigator.userAgent.includes('cordova-app-ios')
      && (this.$route && this.$route.query && this.$route.query.apple_sign_in_token)
    ) {
      this.$store.dispatch('loginUser', { token: this.$route.query.apple_sign_in_token });
    }
  },
  destroyed() {
    this.$store.dispatch('clearErrors');
  },
};
</script>
